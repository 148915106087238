import React, {useState} from 'react';
import {Link as ScrollLink} from "react-scroll";


function NavBar({links}: { links: Array<{ name: string; to: string }> }) {
    // change nav style when scrolling
    const [navFill, setNavFill] = useState(false);
    const changeNavStyle = () => {
        window.scrollY >= 10 ? setNavFill(true) : setNavFill(false);
    }
    window.addEventListener('scroll', changeNavStyle);

    return (
        <div className={"nav"}>
            <div className={navFill ? "nav-links-filled" : "nav-links"}>
                {links.map((link: { name: string, to: string }) => {
                        return (
                            <ScrollLink key={link.to + link.name} activeClass={"active"} className="nav-links-item" offset={-100} smooth spy
                                        to={link.to}>{link.name}</ScrollLink>
                        )
                    }
                )}
            </div>
        </div>
    )
}

export default NavBar;
