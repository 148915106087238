import React from 'react';
import {ReactComponent as LinkedIn} from '../images/linkedin.svg';
import {ReactComponent as GitHub} from '../images/github.svg';

function Footer() {
    return (
        <footer className={"footer"}>
            <span>© {new Date().getFullYear()} Thomas Rüegg, Switzerland</span>
            <div className={"footer-social"}>
                <a href={"https://www.linkedin.com/in/thomasruegg/"} target={"_blank"} rel="noreferrer">
                    <LinkedIn/>
                </a>
                <a href={"https://github.com/thomasruegg"} target={"_blank"} rel="noreferrer">
                    <GitHub/>
                </a>
            </div>
        </footer>
    )
}

export default Footer;
