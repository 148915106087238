import React from 'react';
import { BrowserRouter, Routes } from "react-router-dom";
import './app.scss';
import NavBar from "./components/NavBar";
import Hero from "./components/Hero";
import Footer from "./components/Footer";
import About from "./components/About";
import Work from "./components/Projects";
import Blog from "./components/Blog";


function App() {
    return (
        <BrowserRouter>
            <div className="App">
                <NavBar links={links} />
                <Hero />
                <main className={"main"}>
                    <About />
                    <Work />
                    <Footer />
                </main>
            </div>
            <Routes>
                {/*<Route path="/" element={<Home />} />*/}
                {/*<Route path="/about" element={<About />} />*/}
                {/*<Route path="/work" element={<Work />} />*/}
                {/*<Route path="/writings" element={<Blog />} />*/}
                {/*<Route path="/chat" element={<Chat />} />*/}
            </Routes>
        </BrowserRouter>
    );
}

const links = [
    { name: 'Home', to: 'home' },
    { name: 'About', to: 'about' },
    { name: 'Projects', to: 'projects' },
];


export default App;
