import React from 'react';
import gradient from '../images/gradient.webp';
import stars from '../images/stars.svg';

function Hero() {
    return (
        <section id={"home"} className={"hero"}>
            <img src={gradient} alt="gradient" className={"gradient"} />
            <img src={stars} alt="stars" className={"hero-stars"} />

            <div className={"hero-wrapper"}>
                <h1 className={"hero-wrapper-title"}>
                    <span>Hello, I'm Thomas. </span>
                    <span>A Digital Craftsman.</span>
                </h1>
                <div className={"hero-wrapper-description"}>
                    Passionate about developing innovative solutions, I thrive on the challenges and creativity that programming brings.
                </div>
            </div>
        </section>
    )
}

export default Hero;
